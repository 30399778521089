<template>
  <step-lesson-layout :title="$t('module1.part1.lesson2.title')" :title-annotation="$t('module1.part1.lesson2.titleAnnotation')">
    <template v-slot:content>
      <!-- BLOCK 1 -->
      <div class="objectives-tabs">
        <app-tabs :tabs="objectifsTabs">
          <template v-slot:tab="{ title, label, annotation, icon, isCurrent, color, name }">
            <div class="objectif-tab" :class="{ current: isTabHovered ? name === hoveredTab : isCurrent, [color]: true }" @mouseenter="onHoverTab(name)" @mouseleave="onLeaveTab(name)">
              <div class="text-col">
                <p class="label">{{ label }}</p>
                <p class="title">{{ title }}</p>
                <p class="annotation">{{ annotation }}</p>
              </div>
              <div v-html="icon" class="icon"></div>
            </div>
          </template>
          <template v-slot:content="{ image, name }">
            <div class="tab-content">
              <img :src="isTabHovered ? hoveredTabImage : image" :key="name" :alt="name">
            </div>
          </template>
        </app-tabs>
      </div>

      <!-- BLOCK 2 -->
      <div class="training-question-block">
        <p class="training-question-title">{{ $t('module1.part1.lesson2.block2.quizzLabel') }}</p>
        <p class="training-question-label">{{ $t('module1.part1.lesson2.block2.quizzQuestion') }}</p>
        <div class="training-question-content">
          <quizz-image-only :answers="quizzAnswers" :right-answer="quizzRightAnswer"></quizz-image-only>
        </div>
      </div>

      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppTabs from '@/components/AppTabs'
import QuizzImageOnly from '@/components/training/QuizzImageOnly'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'

export default {
  name: 'Module1Part1Lesson2',
  components: { AppTabs, StepLessonLayout, QuizzImageOnly, AppButtonLayout },
  emits: ['next-tab'],
  data () {
    return {
      hoveredTab: '',
      objectifsTabs: [
        {
          name: 'passoire',
          image: require('@/assets/module1/part1/objectif-reno-passoire.svg'),
          label: this.$t('module1.part1.lesson2.block1.passoire.label'),
          title: this.$t('module1.part1.lesson2.block1.passoire.title'),
          annotation: this.$t('module1.part1.lesson2.block1.passoire.annotation'),
          icon: require('@/assets/module1/part1/passoire-icon.svg?inline'),
          color: 'red'
        },
        {
          name: 'bbc',
          image: require('@/assets/module1/part1/objectif-reno-bbc.svg'),
          label: this.$t('module1.part1.lesson2.block1.bbc.label'),
          title: this.$t('module1.part1.lesson2.block1.bbc.title'),
          annotation: this.$t('module1.part1.lesson2.block1.bbc.annotation'),
          icon: require('@/assets/module1/part1/bbc-icon.svg?inline'),
          color: 'green'
        },
        {
          name: 'conso',
          image: require('@/assets/module1/part1/objectif-reno-conso.svg'),
          label: this.$t('module1.part1.lesson2.block1.conso.label'),
          title: this.$t('module1.part1.lesson2.block1.conso.title'),
          icon: require('@/assets/module1/part1/reduce-conso-icon.svg?inline'),
          color: 'yellow'
        }
      ],
      quizzAnswers: [
        {
          name: 'low',
          label: this.$t('module1.part1.lesson2.block2.quizzAnswerLow'),
          image: require('@/assets/module1/part1/national-nb-reno-low.svg')
        },
        {
          name: 'medium',
          label: this.$t('module1.part1.lesson2.block2.quizzAnswerMedium'),
          image: require('@/assets/module1/part1/national-nb-reno-medium.svg')
        },
        {
          name: 'high',
          label: this.$t('module1.part1.lesson2.block2.quizzAnswerHigh'),
          image: require('@/assets/module1/part1/national-nb-reno-high.svg')
        }
      ],
      quizzRightAnswer: 'medium'
    }
  },
  computed: {
    isTabHovered () {
      return this.hoveredTab !== ''
    },
    hoveredTabImage () {
      return this.objectifsTabs.find(tab => tab.name === this.hoveredTab).image
    }
  },
  methods: {
    onHoverTab (tabName) {
      if (!this.isTabHovered) {
        this.hoveredTab = tabName
      }
    },
    onLeaveTab (tabName) {
      if (this.isTabHovered && this.hoveredTab === tabName) {
        this.hoveredTab = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.objectives-tabs {
  background-color: #fff;
  border-radius: $radius;
  padding: $space-xs;
  .objectif-tab {
    cursor: pointer;
    border-radius: $radius;
    padding: $space-s;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .label {
      font-size: $fz-xs;
      font-weight: $fw-l;
      text-transform: uppercase;
    }
    .title {
      font-family: $ff-big;
      font-weight: $fw-l;
    }
    .annotation {
      font-size: $fz-s;
      font-weight: $fw-m;
    }
    .icon {
      margin-right: $space-s;
    }
    &.current {
      &.red {
        background-color: $c-error;
        color: #fff;
      }
      &.green {
        background-color: $c-primary;
        color: #fff;
      }
      &.yellow {
        background-color: #f6a001;
        color: #fff;
      }
    }
    &.red {
      color: $c-error;
    }
    &.green {
      color: $c-primary;
    }
    &.yellow {
      color: #f6a001;
    }
    .title {
      word-break: break-word;
    }
  }
  .tab-content {
    display: flex;
    justify-content: center;
    padding: $space-ml 46px $space-m 0;
  }
}

@media (min-width: 420px) {
  .objectives-tabs {
    .objectif-tab {
      min-height: 274px;
      flex-direction: column;
      justify-content: space-between;
      .title {
        margin-top: $space-s;
      }
      .annotation {
        margin-top: $space-xs;
      }
      .icon {
        margin-top: $space-m;
        margin-right: 0;
      }
    }
  }
}
@media (min-width: $bp-tablet) {
  .objectives-tabs {
    .objectif-tab {
      height: 100%;
    }
    .tab-content {
      padding: $space-m;
    }
  }
}
</style>

<style lang="scss">
.objectives-tabs {
  .app-tabs {
    .tabs-wrapper .tabs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .tab-wrapper {
        align-self: stretch;
      }
    }
  }
  .objectif-tab {
    .icon svg {
      path, text {
        transition: $color-transition;
      }
    }
    &.red {
      .icon svg {
        .stroke {
          stroke: $c-error;
        }
        .fill {
          fill: $c-error;
        }
      }
    }
    &.yellow {
      .icon svg {
        .stroke {
          stroke: #f6a001;
        }
        .fill {
          fill: #f6a001;
        }
      }
    }
    &.current {
      &.red {
        .icon svg {
          .stroke {
            stroke: #fff;
          }
          .fill {
            fill: #fff;
          }
        }
      }
      &.green {
        .icon svg {
          .fill {
            fill: #fff;
            &.background {
              fill: $c-primary;
            }
          }
        }
      }
      &.yellow {
        .icon svg {
          .fill {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media (min-width: 420px) {
  .objectives-tabs {
    .app-tabs {
      .tabs-wrapper .tabs {
        flex-direction: row;
        align-items: flex-start;
        .tab-wrapper {
          flex: 1 1 auto;
          min-width: 120px;
        }
      }
    }
  }
}

@media (min-width: 460px) {
  .objectives-tabs {
    .app-tabs {
      .tabs-wrapper .tabs {
        .tab-wrapper {
          flex: 1 1 0;
          min-width: 120px;
        }
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .objectives-tabs {
    .app-tabs {
      display: flex;
      align-items: center;
      .tabs-wrapper {
        max-width: 475px;
      }
      .tab-content {
        flex-grow: 1;
      }
    }
  }
}
</style>
