<template>
  <div class="quizz-image-only training-quizz-wrapper" :class="{ 'show-answers': hasAnswered }">
    <div v-for="answer in answers"
      :key="answer.name"
      class="answer quizz-answer"
      :class="{ checked: isCurrentAnswer(answer), right: isRightAnswer(answer) }"
       @click="setAnswer(answer)"
    >
      <img class="image" :src="answer.image" :alt="answer.name">
      <p class="label">
        {{ answer.label }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  name: 'QuizzImageOnly',
  props: {
    answers: {
      type: Array,
      required: true
    },
    rightAnswer: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const currentAnswer = ref(null)

    const hasAnswered = computed(() => {
      return currentAnswer.value !== null
    })

    const isCurrentAnswer = (answer) => {
      return currentAnswer.value?.name === answer.name
    }

    const setAnswer = (answer) => {
      if (!hasAnswered.value) {
        currentAnswer.value = answer
      }
    }

    const isRightAnswer = (answer) => {
      return props.rightAnswer === answer.name
    }

    return { currentAnswer, hasAnswered, isCurrentAnswer, setAnswer, isRightAnswer }
  }
}
</script>

<style lang="scss" scoped>
.quizz-image-only {
  display: grid;
  gap: $space-xs;
  grid-template-columns: repeat(3, 1fr);
  .answer {
    max-width: 120px;
    padding: $space-ml 0;
    img.image {
      width: 100%;
      max-width: 70px;
      padding: 0 $space-s;
    }
    .label {
      margin-top: $space-s;
      font-weight: $fw-m;
    }
  }
  &:not(.show-answers) {
    .answer {
      transition: $color-transition;
      &:hover {
        border-color: $c-secondary;
        box-shadow: none;
      }
    }
  }
}

@media (min-width: 450px) {
  .quizz-image-only {
    grid-template-columns: repeat(3, 120px);
    .answer {
      height: 170px;
      img.image {
        padding: 0;
      }
    }
  }
}
</style>
